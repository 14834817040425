import './Portal.css';
import React, { useEffect, useState } from 'react';
import apiCall from '../../services/api';
import { Redirect } from 'react-router-dom';
import * as MaterialDesign from 'react-icons/md';

function Portal() {
    const [status, setStatus] = useState(null);
    const [apps, setApps] = useState([]);
    const [userData, setUserData] = useState([]);
    useEffect(() => {
        apiCall({
            url: '/api/portaldata',
            method: 'GET',
        }).then(
            result => {
                if (result.success) {
                    setApps(result.apps);
                    setUserData(result.user);
                    setStatus(true);
                } else setStatus(false);
            },
            // Remarque : il est important de traiter les erreurs ici
            // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
            // des exceptions provenant de réels bugs du composant.
            error => {
                setStatus(false);
                console.error(error);
            }
        );
    }, []);

    function disconnect() {
        window.location = '/api/logout';
    }

    function renderApps() {
        if (apps.length === 0) {
            return 'Impossible de trouver vos applications.';
        }
        return apps.map(app => {
            let DisplayLogo = <></>;
            if (app.icon) {
                DisplayLogo = MaterialDesign[app.icon];
            } else if (app.image) {
                DisplayLogo = () => (
                    <img src={`/img/apps/${app.image}`} class="app-logo-image" alt="app logo" />
                );
            }

            if (app.proxy) {
                return (
                    <div
                        key={app.id}
                        onClick={() => {
                            window.location = `/api/${app.id}/#`;
                        }}
                        className="app selectable"
                    >
                        <div class="app-logo-wrapper">
                            <DisplayLogo size="50" />
                        </div>

                        {app.name}
                    </div>
                );
            } else if (app.link) {
                return (
                    <div
                        key={app.id}
                        onClick={() => {
                            window.open(app.link, '_blank');
                        }}
                        className="app selectable"
                    >
                        <div class="app-logo-wrapper">
                            <DisplayLogo size="50" />
                        </div>
                        {app.name}
                    </div>
                );
            } else
                return (
                    <div key={app.id} className="app selectable disabled">
                        <DisplayLogo size="50" />
                        {app.name}
                    </div>
                );
        });
    }

    if (status === null) {
        return 'Chargement...';
    } else if (status === false) {
        return <Redirect to="/"></Redirect>;
    } else
        return (
            <div className="page">
                <div class="top-section">
                    <img alt="app logo " src="/img/logo.png" className="mainLogo"></img>
                    <div class="userInfo">
                        <b>{userData.displayName}</b>
                        <button className="disconnect" onClick={disconnect}>
                            Se déconnecter
                        </button>
                    </div>
                </div>

                <div className="apps">{renderApps()}</div>
            </div>
        );
}

export default Portal;
