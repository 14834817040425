import './NotFound.css';

function NotFound() {
    return (
        <div className="page">
            <h1>404 Not Found</h1>
        </div>
    );
}

export default NotFound;
