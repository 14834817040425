import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import './Login.css';
import apiCall from '../../services/api';

function Login() {
    const [loginModes, setLoginModes] = useState({});

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loginError, setLoginError] = useState('');

    useEffect(() => {
        apiCall({
            url: '/api/loginconfig',
            method: 'GET',
        }).then(
            result => {
                setIsLoggedIn(result.isLoggedIn);
                setLoginModes(result);
            },
            // Remarque : il est important de traiter les erreurs ici
            // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
            // des exceptions provenant de réels bugs du composant.
            error => console.error(error)
        );
    }, []);

    function updateUsername(event) {
        setUsername(event.target.value);
    }

    function updatePassword(event) {
        setPassword(event.target.value);
    }

    function doPasswordLogin() {
        apiCall({
            url: '/api/login',
            method: 'POST',
            body: JSON.stringify({
                username,
                password,
            }),
        }).then(
            result => {
                if (result.success) {
                    setIsLoggedIn(true);
                } else {
                    setLoginError('Login/Password Error');
                }
            },
            // Remarque : il est important de traiter les erreurs ici
            // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
            // des exceptions provenant de réels bugs du composant.
            error => {
                console.error(error);
            }
        );
    }

    if (isLoggedIn) {
        return <Redirect to="/portal" />;
    }

    const googleAuth = <a href="/auth/google">Google via Google</a>;
    const passwordAuth = (
        <>
            <label htmlFor="username">Username</label>
            <input value={username} onChange={updateUsername} type="text" name="username" />
            <label htmlFor="password">Password</label>
            <input value={password} onChange={updatePassword} type="password" name="password" />
            <div className="error-message">{loginError}</div>
            <button type="button" onClick={doPasswordLogin}>
                Connexion
            </button>
        </>
    );

    return (
        <div className="login-page">
            <img alt="app logo " src="/img/logo.png" className="mainLogo"></img>
            {loginModes.password_auth ? passwordAuth : ''}
            {loginModes.google_auth ? googleAuth : ''}
            {!loginModes.google_auth && !loginModes.password_auth
                ? "Aucune méthode d'authentification trouvée"
                : ''}
        </div>
    );
}

export default Login;
